import { ApiProfile } from '../api/auth';

export interface AuthState {
    profile?: ApiProfile,
}

export const setProfile = (profile?: ApiProfile) => {
    return {
        type: 'SET_PROFILE',
        profile,
    };
}

export default (state: AuthState, action: any) => {
    return {
        ...state,
        profile: action.profile,
    };
}
