import React, { FC, HTMLAttributes, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle, faTimesCircle, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import styles from './styles.module.scss';
import Button from '../Button';

export interface MessageProps extends HTMLAttributes<HTMLDivElement> {
    autoHide?: boolean | number,
    content?: string,
    error?: boolean,
    fixed?: boolean,
    icon?: IconDefinition,
    onClose?: () => void,
    success?: boolean,
    title?: string,
    warning?: boolean,
}

export type MessageType =  'error' | 'warning' | 'success';

const Message: FC<MessageProps> = ({
    autoHide,
    children,
    className,
    content,
    error,
    fixed,
    icon,
    onClose,
    success,
    title,
    warning
}) => {
    let customIcon = undefined;

    if (icon) {
        customIcon = icon;
    } else {
        if (error) customIcon = faTimesCircle;
        else if (warning) customIcon = faExclamationTriangle;
        else if (success) customIcon = faCheckCircle;
    }

    useEffect(() => {
        if (autoHide && onClose) {
            setTimeout(() => onClose(), autoHide === true ? 2500 : autoHide);
        }
    }, [autoHide, onClose]);

    return (
        <div
            className={[
                styles.base,
                error && styles.error,
                warning && styles.warning,
                success && styles.success,
                fixed && styles.fixed,
                className,
            ].join(' ')}
        >
            {customIcon && <FontAwesomeIcon icon={customIcon} />}
            <div>
                {title && <h6>{title}</h6>}
                {content ? <p>{content}</p> : children}
            </div>
            {onClose && (
                <Button
                    icon={faTimes}
                    onClick={() => onClose()}
                    trigger
                />
            )}
        </div>
    );
}

export default Message;
