import React, { FC, useRef, useEffect, useState } from 'react';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import Button from '../../elements/Button';
import Segment from '../../elements/Segment';
import styles from './styles.module.scss';

interface Position {
    left?: number | string,
    top?: number | string,
    right?: number | string,
    bottom?: number | string,
}

const Dropdown: FC = ({ children }): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ position, setPosition ] = useState<Position>({});

    const toggleDropdown = () => {
        if (containerRef.current) {
            const pos: Position = {};
            const rect = containerRef.current.getBoundingClientRect();

            if (rect.x + rect.width + 16 > window.innerWidth) {
                pos.left = 'auto';
                pos.right = 0;
            }
            if (rect.x < 16) {
                pos.left = 0;
                pos.right = 'auto';
            }
            if (rect.y + rect.height + 16 > window.innerHeight) {
                pos.top = 'auto';
                pos.bottom = 16;
            }
            if (rect.x < 16) {
                pos.top = 16;
                pos.bottom = 'auto';
            }

            setPosition(pos);
        }

        setIsOpen(!isOpen);
    }

    useEffect(() => {
        window.addEventListener('click', () => setIsOpen(false));
        return () => window.removeEventListener('click', () => setIsOpen(false));
    });

    return (
        <div
            onClick={(e) => e.stopPropagation()}
            style={{ position: 'relative' }} 
        >
            <Button
                icon={faEllipsisV}
                onClick={() => toggleDropdown()}
                trigger
                triggered={isOpen}
            />
            <div
                className={[
                    styles.dropdownContainer,
                    isOpen && styles.dropdownContainerIsOpen,
                ].join(' ')}
                ref={containerRef}
                style={position}
            >
                <Segment
                    card
                    padding="none"
                    shadow
                >
                    {children}
                </Segment>
            </div>
        </div>
    );
}

export default Dropdown;
