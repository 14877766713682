import React, { FC, HTMLAttributes, isValidElement } from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import Button from '../../elements/Button';
import styles from './styles.module.scss';

type ModalSize = 'large' | 'medium' | 'small';

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
    footer?: string | JSX.Element,
    header?: string | JSX.Element,
    onClose?: () => void,
    open: boolean,
    size?: ModalSize,
}

const Modal: FC<ModalProps> = ({
    children,
    className,
    footer,
    header,
    onClose,
    open,
    size,
    ...props
}): JSX.Element => {
    return (
        <div
            className={[
                styles.overlay,
                open ? styles.showModal : '',
            ].join(' ')}
            onClick={onClose}
        >
            <div
                className={[
                    styles.modal,
                    size ? styles[size] : '',
                    className,
                ].join(' ')}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
                {...props}
            >
                {header && (
                    <div className={styles.header}>
                        <div>
                            {header && isValidElement(header)
                                ? header
                                : <h4 className={styles.title}>{header}</h4>
                            }
                        </div>
                        <Button
                            icon={faTimes}
                            onClick={onClose}
                            trigger
                        />
                    </div>
                )}
                <div className={styles.body}>
                    {children}
                </div>
                {footer && <div className={styles.footer}>
                    {footer}
                </div>}
            </div>
        </div>
    );
}

export default Modal;
