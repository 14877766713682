import React, { useState, useEffect } from 'react';
import Message, { MessageProps, MessageType } from '../../elements/Message';

export const toast = (props: string | MessageProps, type?: MessageProps | MessageType): void => {
    let detail: MessageProps = {};
    
    if (typeof props === 'string') {
        if (type) {
            if (typeof type === 'string') {
                detail[type] = true;
            } else {
                detail = type;
            }
            detail.content = props;
        } else {
            detail.success = true;
            detail.content = props;
        }
    } else {
        detail = props;
    }

    dispatchEvent(new CustomEvent('apu-toast', { detail }));
}

export default (): JSX.Element => {
    const [ messages, setMessages ] = useState<MessageProps[]>([]);

    const handleAddMessage = ({ detail }: any) => {
        setMessages([
            ...messages,
            detail,
        ]);
    }

    useEffect(() => {
        window.addEventListener('apu-toast', handleAddMessage);
        return () => window.removeEventListener('apu-toast', handleAddMessage);
    });

    const handleClose = (index: number): void => {
        setMessages(messages.filter((o, i) => i !== index));
    }

    return (
        <div style={{ position: 'fixed', bottom: 0, right: '1rem', zIndex: 1000 }}>
            {messages.reverse().map((props, index) => (
                <Message
                    autoHide={props.success}
                    key={`msg-${index}`}
                    onClose={() => handleClose(index)}
                    {...props}
                />
            ))}
        </div>
    );
}
