import React, { FC, HTMLAttributes, CSSProperties, isValidElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import styles from './styles.module.scss';

export interface TableCellProps extends HTMLAttributes<HTMLTableDataCellElement & HTMLTableHeaderCellElement> {
    align?: 'center' | 'left' | 'right',
    as?: 'td' | 'th',
    collapsing?: boolean,
    icon?: IconDefinition | JSX.Element,
}

const TableCell: FC<TableCellProps> = ({
    align,
    as,
    children,
    className,
    collapsing,
    icon,
    ...props
}): JSX.Element => {
    const Elem = as ? as : 'td';

    const style: CSSProperties = {};
    if (props.onClick) {
        style.cursor = 'pointer';
    }
    
    return (
        <Elem
            className={[
                collapsing && styles.collapsing,
                align && styles[`${align}Align`],
                className,
            ].join(' ')}
            style={style}
            {...props}
            >
            {icon && (isValidElement(icon) ? icon : <FontAwesomeIcon icon={icon as IconDefinition} style={{ marginRight: children ? 5 : 0 }} />)}
            {children}
        </Elem>
    );
}

export default TableCell;
