import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { Segment } from '../../ApuKit';
import api from '../../api';
import Logo from '../../components/Logo';
import { collapse } from '../Drawer';
import { AppState } from '../../store';
import styles from './styles.module.scss';

export default () => {
    const profile = useSelector((state: AppState) => state.auth.profile);

    return (<>
        <Segment className={styles.base} padding="compact">
            <div className={styles.logo}>
                <FontAwesomeIcon icon={faBars} onClick={() => collapse()} />
                <Logo />
            </div>
            <div className={styles.container}>
                <b onClick={() => api.logout()}>{profile?.username}</b>
            </div>
        </Segment>
        <div className={styles.push} />
    </>);
}
